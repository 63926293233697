@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Source Code Pro', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-container {
  margin: 0 0.7em;
}

.App-header {
  margin-bottom: 3.5rem;
}

.App-header h2 {
  font-size: 1.3em;
}
.git {
  color: var(--nord13);
}

.get-command-section {
  margin-top: 1em;
  width: 100%;
  font-size: 0.8em;
}

.input-command {
  height: 4rem;
  padding: 0.5em 1.5em;
  font-size: 0.9em;
  border-radius: 5em;
  border: 0;
  outline: 0;
  margin-bottom: 1.3em;
  font-family: inherit;
  width: inherit;
}

.get-command-button {
  font-size: 1em;
  height: 3em;
  border: 0;
  border-radius: 5rem;
  width: 60%;
  outline: 0;
  font-family: inherit;
  background-color: var(--nord13);
  margin-bottom: 1.5rem;
}

@media (min-width: 465px) {
  .input-command {
    height: 4.5em;
  }
}

@media (min-width: 768px) {
  .input-command {
    width: 80%;
    height: 4em;
  }

  .get-command-button {
    width: 40%;
  }
}

h1,
h2,
h3 {
  margin-block-start: 0;
  margin-block-end: 0;
}

.invalid-command-error {
  color: var(--nord11);
  font-size: 1.5em;
}

.App-header h1,
.get-command-section h2,
.get-command-section h3 {
  margin-block-end: 0.5em;
}
